

import ComponentLoader from '/root/project/node_modules/@uplight/marketplace-components/lib/ComponentLoader';
import ResultsGrid from '/root/project/node_modules/@uplight/marketplace-components/src/search/ResultsGrid/containers/ResultsGrid';
import HomepageHero from '/root/project/node_modules/@uplight/marketplace-components/src/slots/HomepageHero/HomepageHero';
import HomepageFeaturedPrograms from '/root/project/node_modules/@uplight/marketplace-components/src/slots/HomepageFeaturedPrograms/HomepageFeaturedPrograms';
import HomepageFeaturedProducts from '/root/project/node_modules/@uplight/marketplace-components/src/slots/HomepageFeaturedProducts/HomepageFeaturedProducts';
import HomepageFeaturedCategories from '/root/project/node_modules/@uplight/marketplace-components/src/homepage/ShopByCategory/ShopByCategory';
import ProductInfoWrapper from '/root/project/node_modules/@uplight/marketplace-components/src/product/ProductInfoWrapper/ProductInfoWrapper';
import MediaFeature from '/root/project/node_modules/@uplight/marketplace-components/src/product/MediaFeature/MediaFeature';
import Details from '/root/project/node_modules/@uplight/marketplace-components/src/product/Details/containers/Details';
import ComparisonTable from '/root/project/node_modules/@uplight/marketplace-components/src/product/ComparisonTable/containers/ComparisonTable';
import Banner from '/root/project/node_modules/@uplight/marketplace-components/src/search/Banner/Banner';


const componentList = [
  ResultsGrid,
  HomepageHero,
  HomepageFeaturedPrograms,
  HomepageFeaturedProducts,
  HomepageFeaturedCategories,
  ProductInfoWrapper,
  MediaFeature,
  Details,
  ComparisonTable,
  Banner,
];

new ComponentLoader(componentList).load();
